import React from "react";
import "./Css/Footer.css";

import Logo from './Media/logoWhite.png';

const Footer = () =>{
    return(
        <div className="FooterContainer">
            <div className="FooterLogo">
                <img alt="logo" className="FooterImg" src={Logo}/>
            </div>
            <div className="FooterDesc">
                <h3 className="Footerh">TLC Telecomunicazioni srl</h3>
                <p className="Footerp">Desc</p>
                <p className="Footerp">Desc</p>
                <p className="Footerp">Copyright</p>
                <p className="Footerp">Altro</p>
           </div>
        </div>
    )
}
export default Footer;