// @ts-nocheck
import React from "react";
import "./Css/NavBar.css";
import Logo from "./Media/Favicon.jpg";
import Menu from "./Media/menu.png";
import { useEffect,useState } from "react";
import obj from "./Login";
import { useNavigate} from "react-router-dom";
const NavBar = ({setStatus}) =>{
    const [view, setview] = useState(window.innerWidth > 600)
    const navigate = useNavigate()
    const [Text,setText] = useState(<></>)
    const styleLinks = view ? {minHeight: '100px'} : {minHeight: '0', maxHeight: '0', height: '0', visibility: 'hidden', opacity: '0'}
    const LogOutFunction = () => {
        localStorage.removeItem("logintoken")
        console.log("LogOut")
        navigate("/Login",{replace: true})
        setStatus({
            status: false,
            Role: ""
        })
    }
    const Links = (
        <div className="NavLinks" id="NavLinks" style={styleLinks} >
            <span className="Link" onClick={()=>navigate('/Bollettino',{replace: true})}>Bollettino TLC-Tel</span>
            <span className="Link" onClick={()=>navigate('/Contratto',{replace: true})}>Contratti On-Line</span>
            <span className="Link" onClick={()=>navigate('/ResellerForm',{replace: true})}>Area Riservata Reseller</span>

            {!view && <>
                {Text}
                <span id="LogOutNav" className="Link" onClick={()=>LogOutFunction()}>Log Out</span>
                </>
            }
            
        </div>
    )
    const LogoContainer = (
        <div className="NavLogo">
            <img src={Logo} onClick={()=>navigate('/',{replace: true})} style={{width: '70px', height: '70px',cursor: 'pointer', borderRadius: '13px', marginLeft: '15px'}} alt="Logo" />
        </div>
    )
    useEffect(() => {
        window.addEventListener('resize', () => {
            setview(window.innerWidth > 600)
        })
    },[])
    useEffect(() => {
        obj.fetch().then(res => {
            if (res.data.Role === "admin")
                setText(
                    <span className="Link" onClick={()=>navigate('/AdminCp',{replace: true})}>Area Admin</span>)
            if (res.data.Role === "user")
                setText( <span className="Link" onClick={()=>navigate('/ResellerCp',{replace: true})}>Pannello Reseller</span>)
        })
    }, [navigate])
    const Transition = () => {
        const el = document.getElementById('NavLinks')
        if (el.style.minHeight ===el.childElementCount * 50 + "px"){
            el.style.transition = "min-height 0.5s ease,visibility 0s, opacity 0.5s ease"
            el.style.minHeight = "0"
            el.style.visibility = "hidden"
            el.style.opacity = "0"
            return
        }
        el.style.visibility = "visible"
        el.style.opacity = "1"
        el.style.transition = "min-height 0.5s ease,visibility 0s, opacity 0.5s ease"
        el.style.minHeight = el.childElementCount * 50 + "px"
    
    }
    if (view)
        return(
            <div className="NavContainer">
                {LogoContainer}
                {Links}
            </div>
        )
    else
        return(
            <>
            <div className="NavContainer">
                <div style={{display: "flex", width: '100%', height: '100px', justifyContent: 'space-between', alignItems: "center"}}>
                    {LogoContainer}
                    <img src={Menu} alt="Menu" style={{height: '50px', width: '50px', cursor: 'pointer', marginRight: '10px'}} onClick={()=>Transition()}/>
                </div>
            </div>
            {Links}
            </>
        )
}
export default NavBar;