import React from "react";
import "./Css/Home.css";
import { useNavigate } from "react-router-dom";
const Home = () => {
    const navigate = useNavigate();
    return (
        <div className="Home">
            {/* <div className="HomePanel" id="FirstPanel"  */}
                <div className="HomePanelTitle" onClick={()=>navigate("/Bollettino",{replace: true})} style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    <span>Bollettino TLC</span>
                </div>
                <div className="HomePanelTitle" onClick={()=>navigate("/Contratto",{replace: true})} style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    <span >Contratti On-Line</span>
                </div>
        </div>
    )
}
export default Home;