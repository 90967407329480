// @ts-nocheck
import ApiConnect from "./ApiConnect";
import React, { useEffect } from "react";
import Warning from "./Warning";
const ReSend = (recipient) =>{
    const [cooldown,setCooldown] = React.useState(0)
    const [status,setstatus] = React.useState("Reinvia")
    const [error,setError] = React.useState("")
    const ReqReSend = () =>{
        ApiConnect.post("/form/sendAgain",{
            recipient
        },{
            headers: {
                "x-access-token": localStorage.getItem("x-access-token")
            }
        }).then((res) =>{
            const Errori = {
                0 : "Errore sessione non trovata",
                1 : "Errore troppi reinvii",
                2 : "Token reinviato"
            }
            setError(Errori[res.data.StatusCode])
        })
        setCooldown(60)
    }
    useEffect(()=>{
        if (cooldown !== 0){
            setTimeout(() =>{
                setstatus(cooldown - 1 + "s")
                setCooldown(cooldown - 1)
            },1000)
        }
        else
            setstatus("Reinvia")
    },[status,cooldown])
    useEffect(() =>{
        if (error !== ""){
            document.getElementById("ErrorModule").innerHTML = error
            document.getElementById("Basic").showModal()
        }
    },[error])
    return (
        <>
        <Warning id="Basic" title={<span className="titledialog">Attenzione!</span>} >
            <div className="desc">
                <span style={{fontSize: '20px', padding: '10px'}} id="ErrorModule"></span>
            </div>
        </Warning>
        <button className="btn" style={{margin: 0}}  disabled={cooldown !== 0} onClick={ReqReSend}><span className="Title" style={{fontSize: '30px',color: 'inherit'}}>{status}</span></button>
        </>
    )
}
export default ReSend;