// @ts-nocheck
import React from "react";
import close from './Media/close.png';

const DataViewer = ({Data}) => {
    return (
        <dialog className="DataViewerContainer" id="DataViewer">
        <div className="ButtonContainerDataViewer">
        <img src={close} alt='close.png' className={"ImageDataViewer"} onClick={() => document.getElementById("DataViewer").close()} />
        </div> 
            <div className="DataViewer">
                <span className="DataViewerTitle">Dettagli:</span>
                <ul className="DataViewerList">
                {
                        Object.keys(Data).map((key, index) => {
                            return (
                                <li className="DataViewerElement" key={index}>
                                    <span className="DataViewerKey">{key}:</span>
                                    <span className="DataViewerValue">{Data[key]}</span>
                                </li>
                            )
                        })
                }
                </ul>
            </div>
        </dialog>
    )
}
export default DataViewer;