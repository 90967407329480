import ApiConnect from "./ApiConnect";
import JSZip from "jszip";
const FormStoreData = async(navigate,Offerta,Text_Content) => {
    ApiConnect.post("/form/StoreData",{Text_Content,Offerta},{
        headers: {
            withCredentials: true,
            'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json',
        }
    }).then((res) => {
        localStorage.setItem("x-access-token",res.data["x-access-token"])
        
    })
    navigate("/TokenVerify",{replace: true})
    return
}
const checktype = (file) => {
    return (file.type === "image/jpeg" || file.type === "image/png");
}

const FormRequest = async(FileData) => {
    // @ts-ignore
    if (process.env.NODE_ENV === "production"){
        if (FileData[0] === null || FileData[1] === null)
        {
            return {
                error: true,
                message: "Caricare entrambe le Foto, Fronte e Retro del documento di identità"
            }
        }
        console.log(FileData[0].type,FileData[1].type)
        if (!checktype(FileData[0]) && !checktype(FileData[1]))
        {
            return {
                error: true,
                message: "I file selezionati non sono del formato adatto (JPEG o PNG)"
            }
        }
    }
    const formData = new FormData();
    formData.append('file0', FileData[0]);
    formData.append('file1', FileData[1]);
    // @ts-ignore
    const Cell = document.getElementById("Cell").value
    localStorage.setItem("Cell",Cell)
    // @ts-ignore
    const Email = document.getElementById("Email").value
    localStorage.setItem("Email",Email)
    const res = await ApiConnect.post("/form/CreateToken",
        formData,{
        headers: {
            withCredentials: true,
            "x-access-token": localStorage.getItem("x-access-token"),
            'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'multipart/form-data',
            "e-mail": Email,
            "cell": Cell
        }
    })
    localStorage.setItem("x-access-token",res.data["x-access-token"])   
    return {
        error:false
    }
}

const FormVerify = async() =>{
    return await ApiConnect.post("/form/verify",{
        // @ts-ignore
        Token: document.getElementById("MailToken").value
    },{
        headers: {
            withCredentials: true,
            'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json',
            "x-access-token": localStorage.getItem("x-access-token"),
        }
    }).then(async(res) => {
        console.log(res.data)
        const statuscode = {
            "00" : "Token scaduto", 
            "01" : "Troppi tentativi",
            "02" : "Token non corrispondente",
            "03": "Sessione non trovata o Scaduta",
        }
        if (res.data.Status)
            return ""
        else
            return statuscode[res.data.ErrorCode]
    })
}

const GetContract = async(sessionID) => {
    const res = await ApiConnect.get("/form/GetContract",{
        responseType: 'arraybuffer',
        headers: {
            withCredentials: true,
            'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json',
            "x-access-token": sessionID,
        },
    })
    const chunkSize = 50000; // Chunk size can be adjusted
    const array = new Uint8Array(res.data);
    let result = '';

    for (let i = 0; i < array.length; i += chunkSize) {
        const subArray = array.subarray(i, i + chunkSize);
        result += String.fromCharCode.apply(null, subArray);
    }

    return btoa(result);
}

const ToBase64 = (content) => {
    const chunkSize = 50000; // Chunk size can be adjusted
    const array = new Uint8Array(content);
    let result = '';
    for (let i = 0; i < array.length; i += chunkSize) {
        const subArray = array.subarray(i, i + chunkSize);
        result += String.fromCharCode.apply(null, subArray);
    }
    return btoa(result)
}

const GetOffer = async(sessionID) => {
    const res = await ApiConnect.get("/form/GetOffer",{
        responseType: 'arraybuffer',
        headers: {
            withCredentials: true,
            'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json',
            "x-access-token": sessionID,
        },
    })
    return await JSZip.loadAsync(res.data).then(async(zip) => {
        const arr = []
        let content = await zip.file("Offerta.pdf").async("uint8array")
        arr.push(ToBase64(content))
        content = await zip.file("Quotazione apparati.pdf").async("uint8array")
        arr.push(ToBase64(content))
        return arr
    })
}
const exportables = {FormStoreData,FormVerify, GetContract, FormRequest, GetOffer}

export default exportables;