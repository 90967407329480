import React, { useEffect } from 'react';
import  "./Css/Bollettino.css"
import ParseFile from './ParseFile';
import { saveAs } from 'file-saver';
// @ts-ignore
import Esempio from './docs/Esempio_Bollettino.xlsx';
import LinearProgress from '@mui/material/LinearProgress';
import Warning from './Warning';
// @ts-ignore
import FileBoxInput from './FileBoxInput.tsx';
const ByFile = () => {
    const [File, setFile] = React.useState('Nessun file selezionato...');
    const [FileData, setFileData] = React.useState(null);
    const [Error, setError] = React.useState("");
    const UpdateFile = (e) => {
        setFile(e.target.files[0].name);
        setFileData(e.target.files[0]);
    }
    const saveExample = () => {
        saveAs(Esempio, 'Esempio_Bollettino.xlsx');
    }
    const [loading, setLoading] = React.useState(false);

    const HandleLoading = async() => {
        setLoading(true)
        const res = await ParseFile(FileData)
        if (res.status === false)
            setError(res.message)
        setLoading(false)
    }
    useEffect(() => {
        if (Error !== "")
            // @ts-ignore
            document.getElementById("Basic").showModal();
    }, [Error])
    return(
        <div className='panel'>
            <Warning  title={<span className="titledialog">Attenzione!</span>} id="Basic">
                <div className="desc">
                    <span style={{fontSize: '20px', padding: '10px'}}>{Error}</span>
                </div>
            </Warning>
            <div style={{width: '100%'}}>
            <span className={"SubTitle"}>Crea da file</span>
                <div className='InternalPanel' >
                    <p style={{fontWeight: '500',wordSpacing: '1px',marginTop: '0px'}}>
                        Inserisci i dati del bollettino in un file excel e caricalo qui.
                        Il file deve essere in formato .xlsx e deve contenere i dati in una tabella con le seguenti colonne:
                        <ul>
                            <li>Scadenza</li>
                            <li>Importo</li>
                            <li>Numero Fattura</li>
                            <li>Data Fatturazione</li>
                            <li>Codice Cliente</li>
                            <li>Nome Cliente</li>
                            <li>Indirizzo</li>
                            
                            
                        </ul>
                    </p>
                    <FileBoxInput UpdateFile={UpdateFile} File={File} style={{marginBottom: '5%'}} mimetype='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' />
                    <div className='FlexRow' style={{justifyContent: 'space-between',width: '80%',marginBottom: '-60px'}}>
                        {!loading && <><button className={"btn"} onClick={()=>HandleLoading()} style={{width: '45%'}}>
                            <span style={{fontSize: '30px'}} className={"Title"}>Invia</span></button>
                            <button className={"btn"} onClick={()=>saveExample()} style={{width: '45%'}}><span style={{fontSize: '30px'}} className={"Title"}>Esempio</span></button>
                        </>}   
                        
                    </div>
                        {loading && <div style={{display: 'flex', width: '75%', justifyContent: 'center',alignItems:'center', marginTop: '40px'}}>
                            <LinearProgress style={{width: '100%', height: '10px', borderRadius: '28px', backgroundColor: 'rgb(243, 68, 14,0.5)'}}/>
                        </div>}
                </div>
                
            </div>
        </div>
    )
}
export default ByFile;