// @ts-nocheck
import React, { useEffect } from "react";
import { useState} from "react";
import Reqs from "./FormRequest";
import "./Css/Form.css"
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "./Alert";
import Anag from "./Anag";
import DatiLinea from "./DatiLinea";
import Pagamento from "./Pagamento";
import FormButton from "./FormButton";
import OffertaEl from "./Offerta";
const Form = () =>{
    //const inptImg = useRef()
    //const inptVd = useRef()
    const navigate = useNavigate()
    const location = useLocation()
    const Compiled = location.state?.Compiled
    const [lastCheck, setlastCheck] = useState("")
    const [lastCheckPag, setlastCheckPag] = useState("")
    const [Text_Content,SetText_Content] = useState({})
    const [Offerta,SetOfferta] = useState({
        Checks : [],
        Texts: []
    })
    const [Step,SetStep] = useState(0)
    const decheck = (e,funcUp,Check) =>{
        if (e.target.id === Check){
            funcUp("")
            SetText_Content(prevState => ({...prevState,[e.target.id]:e.target.checked}))
            return;
        }
        if (Check === ""){
          funcUp(e.target.id)
          SetText_Content(prevState => ({...prevState,[e.target.id]:true}))
          return
        }
        const otherElement = document.getElementById(Check); 
        otherElement["checked"]= false;
        funcUp(e.target.id)
        SetText_Content(prevState => ({...prevState,[e.target.id]:true}))
        SetText_Content(prevState => ({...prevState,[Check]: false}))
        return
    }
    const setValue = (e, id) =>{
        SetText_Content(prevState => ({...prevState,[id || e.target.name]:e.target.value}))
    }
    useEffect(()=>{
        const TextContent = JSON.parse(localStorage.getItem("Text_Content"))
        if (TextContent === null)
            return
        SetText_Content(TextContent)
        if (Compiled)
            loadTextField()
        if (Object.keys(TextContent).length > 0 && !Compiled)
            document.getElementById("AlertBackup").showModal()
    },[Compiled])

    const loadTextField = () =>{
        const TextContent = JSON.parse(localStorage.getItem("Text_Content"))
        console.log(TextContent)
        for (const key in TextContent){
            console.log(document.getElementsByName(key),key)
            const element = document.getElementsByName(key)[0]
            if (element === undefined)
                continue
            if (key === "Ip_statico")
                continue
            if (element.type === "checkbox" && TextContent[key] === true && element.checked === false)
                element.click()
            else 
                element.value = TextContent[key]
        }
            
        document.getElementById("AlertBackup").close()
    }
    const Shrink = (Name) =>{
        return Text_Content[Name] !== "" && Text_Content[Name] !== undefined
    }
    document.addEventListener("focusout",()=>{localStorage.setItem("Text_Content",JSON.stringify(Text_Content))})

    const CheckPiano = (pianoLinea) => {
        let Piano = ""
        if ((pianoLinea[0] === "FTTH" && (pianoLinea[1] === "0" || pianoLinea[1] === "3"))) 
            Piano = "FTTH"
        else if ((pianoLinea[0] === "FTTC" && (pianoLinea[1] === "0" || pianoLinea[1] === "3"))) 
            Piano = "FTTC"
        else if ((pianoLinea[0] === "FTTH" && (pianoLinea[1] === "2" || pianoLinea[1] === "4")))
            Piano = "FTTHVOIP"
        else if (pianoLinea[0] === "VOIP")
            Piano = "VOIP"
        else if (pianoLinea[0] === "FTTC" && (pianoLinea[1] === "2" || pianoLinea[1] === "4"))
            Piano = "FTTCVOIP"
        console.log(Piano)
        return Piano
    }
    const OnSend = () => {
        const elements = document.getElementsByTagName("input")
        const Checks = []
        const Texts = []            
        for (let i = 0; i < elements.length; i++)
        {
            if (getComputedStyle(elements[i]).visibility === "hidden")
                continue
            if (elements[i].type === "checkbox" )
                Checks.push(elements[i].checked)
            if (elements[i].id === "IpPubblicoInput")
                Texts.unshift(elements[i].value)
            if (elements[i].type === "text")
                Texts.push(elements[i].value)
        }
        SetOfferta({
            Checks:Checks,
            Texts:Texts,
            Piano: CheckPiano([!Text_Content["Piano"] ? "FTTH" : Text_Content["Piano"] ,Text_Content["Servizio"]])
        })
    }
    return(
        <div style={{display:'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>
            <Alert id="AlertBackup" title={<span className="titledialog">Dati in memoria</span>}>
                <p style={{fontSize: '20px', padding: '0px', width: '100%', textAlign: 'center'}}>Sono stati trovati dati in memoria, si desidera caricare i sudetti?</p>
                <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',gap: '10px', width: '100%'}}>
                    <button className="btn" style={{width: '30%', margin: 0}} onClick={()=>{document.getElementById("AlertBackup").close(); SetText_Content({})}}><span className="Title" style={{fontSize: '30px'}}>No</span></button>
                    <button className="btn" style={{width: '30%', margin: 0}} onClick={loadTextField}><span className="Title" style={{fontSize: '30px'}}>Si</span></button>
                </div>
            </Alert>
            {   
                process.env.NODE_ENV === "development" &&
                <button onClick={()=>OnSend()}>Invia Offerta</button>
            }
            {
                process.env.NODE_ENV === "development" &&
                <button onClick={()=>console.log(Text_Content, Offerta)}>Log</button>
            }
            {
                process.env.NODE_ENV === "development" &&
                <button onClick={()=>Reqs.FormStoreData(navigate,Offerta,Text_Content)}>Tenta Invio Form</button>
            }
            <span className="Title">Contratto On-Line</span>
            {
                Step === 0 && <>
                    <Anag funcs={{SetValue:setValue,Shrink:Shrink}} />
                    <FormButton changeState={SetStep} state={Step} />
                </>
            }
            {
                Step === 1 && <>
                    <DatiLinea funcs={{setValue:setValue,Shrink:Shrink,decheck:decheck}} States={{setlastCheck,lastCheck,Text_Content:Text_Content}} />
                    <FormButton changeState={SetStep} state={Step} />
                </>
            }
            {
                Step === 2 && <>
                    <OffertaEl pianoLinea={[!Text_Content["Piano"] ? "FTTH" : Text_Content["Piano"] ,Text_Content["Servizio"]]} setState={SetOfferta} />
                    <FormButton changeState={SetStep} state={Step} onClick={OnSend} />
                </>
            }
            {
                Step === 3 && <>
                    <Pagamento funcs={{setValue,decheck,Shrink}} states={{setlastCheckPag,lastCheckPag,Text_Content}} />
                    <div style={{width: '80%',margin: '20px', display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                        <button className="btn" onClick={(e)=>{Reqs.FormStoreData(navigate,Offerta,Text_Content)}} ><span className="Title" style={{fontSize: '30px'}}>Invia</span></button>
                    </div>
                    </>
            }
            
                
        </div>
    )
}

export default Form;