import React from "react"
import { CheckListItem } from "./Offerta"
const Tariffe =() => {
    const IDs = ["Tariffa1","Tariffa2"]
    const HandleOneCheck = (e) => {
        IDs.forEach((id) => {
            if (id === e.target.id) return
            const element = document.getElementById(id)
            console.log(element)
            element["checked"]= false;
        })
    }
    return (
        <>
            <CheckListItem
                FirstText={
                    <>
                        <span style={{all: 'inherit'}}>
                            Profilo: Consumo
                        </span>
                        <span className="SubTitleOfferta">
                            Urbante - Interurbane - Cellulari nazionali: <br /> senza scatto alla risposta
                        </span>
                    </>
                }
                SecondText = "0.0498 € al minuto"
                id="Tariffa1"
                onChange={HandleOneCheck}
            />
            <CheckListItem
                FirstText={
                    <>
                        <span style={{all: 'inherit'}}>
                            Profilo: Flat Italia Fissi e Mobili                             
                        </span>
                        <span className="SubTitleOfferta">
                            Chiamate locali e nazionali e verso cellulari illimitate*<br/> GRATUITE
                            senza scatto alla risposta<br/>                            
                        </span>
                        <span className="SubTitleOfferta" style={{fontSize: '0.6em'}}>
                        (*tetto mensile di 900 minuti suddivisi in 300 minuti di urbane, 300 di interurbane e 300 verso cellulari
                            nazionali. Chiamate oltre plafond a 0.0498 € al minuto senza scatto alla risposta)
                        </span>
                    </>
                }
                SecondText = "6.00 € mese"
                id="Tariffa2"
                onChange={HandleOneCheck}
            />
        </>
    )
}
export default Tariffe