// @ts-nocheck
import React, { useEffect } from "react";
import "./Css/ContrattoRes.css"
import { useState } from "react";
import { MenuItem, TextField, Select, FormControlLabel, FormLabel, RadioGroup, Radio } from "@mui/material";
import MuiStyles from "./MuiStyles";
import FileBoxInput from "./FileBoxInput.tsx";
import ResReq from "./ResReq";
import obj from "./Login";
import { useNavigate } from "react-router-dom";
import LogOut from "./LogOut";
import Warning from "./Warning";
import CityAutoComplete from "./CityAutoComplete";

const ContrattoRes = ({ setStastus }) => {
    /* File input, Autocomplete comuni, richiesta backend*/
    const navigate = useNavigate();
    const [Router, setRouter] = useState(false);
    const [RouterName, setRouterName] = useState("AVM Fritz!Box 7530");
    const [Voip, setVoip] = useState(0);
    const [FileName, setFileName] = useState("Nessun File Selezionato...");
    const [File, setFile] = useState(null);
    const [File2, setFile2] = useState(null);
    const [FileName2, setFileName2] = useState("Nessun File Selezionato...");
    const [Button, setbutton] = React.useState(<></>)
    const [Final, setFinal] = React.useState(false)
    const [Ip, setIp] = React.useState("No")
    const [Operatore, setOperatore] = React.useState("Fibercop")
    const [Servizio, setServizio] = React.useState("ADSL")
    const [Error, setError] = React.useState("")
    useEffect(() => {
        obj.fetch().then(res => {
            if (res.data.Role === "admin")
                setbutton(
                    <div className="ButtonCp">
                        <button onClick={() => navigate("/AdminCp", { replace: true })} className="btn" style={{ width: '100%', margin: "0 4% 0 0%" }}>
                            <span className="Title" style={{ fontSize: '30px' }} >Admin</span>
                        </button>
                    </div>)
            if (res.data.Role === "user")
                setbutton(<div className="ButtonCp">
                    <button onClick={() => navigate("/ResellerCp", { replace: true })} className="btn" style={{ width: '100%', margin: "0 1% 0 0%" }}>
                        <span className="Title" style={{ fontSize: '30px' }} >Reseller</span>
                    </button>
                </div>)
        })
    }, [navigate])
    useEffect(() => {
        if (RouterName === "Personalizzato" && !Router)
            setRouterName("AVM Fritz!Box 7530");
    }, [Router, RouterName])

    const SendData = async () => {
        const Collection =await document.getElementsByTagName("input")
        const coll = {}
        for await(const item of Collection)
            coll[item.id] = item.value
        coll["Router"] = Router
        if (!Router)
            coll["RouterName"] = "No"
        coll["Ip"] = Ip
        delete coll[""]
        console.log(coll)
        if (File === null || File2 === null){
            setError("Inserire i documenti")
            document.getElementById("Basic").showModal()
            return
        }
        const resp = await ResReq(coll, [File, File2]);
        if (resp.AuthError)
            navigate("/Login", { replace: true })
        else
            setFinal(true)


    }
    const UpdateFile = (e, setState, setStateName) => {
        setState(e.target.files[0]);
        setStateName(e.target.files[0].name);
    }
    const Anag = (
        <div className="PanelReseller">
            <span className="PanelTitle">Dati Anagrafici Cliente</span>
            <div>
                <div className="RowContainerReseller">
                    <TextField label="Nome" id="Nome" variant="outlined" className="TextFieldReseller" sx={{ ...MuiStyles.TextField }} />
                    <TextField label="Cognome" id="Cognome" variant="outlined" className="TextFieldReseller" sx={{ ...MuiStyles.TextField }} />
                    <TextField label="Codice Fiscale" id="CodiceFiscale" variant="outlined" className="CodiceFiscale" sx={{ ...MuiStyles.TextField }} />
                </div>
                <span className="subtitle">*Per le aziende indicare il legale rappresentante </span>
            </div>
            <div className="RowContainerReseller">
                <TextField label="Ragione Sociale" id="RagioneSociale" variant="outlined" className="RagioneSociale" sx={{ ...MuiStyles.TextField }} />
                <TextField label="Partita IVA" id="PartitaIVA" variant="outlined" className="PartitaIVA" sx={{ ...MuiStyles.TextField }} />
            </div>
            <div>
                <div className="RowContainerReseller">
                    <TextField label="Nome Referente" id="NomeReferente" variant="outlined" className="NomeReferente" sx={{ ...MuiStyles.TextField }} />
                    <TextField label="Cognome Referente" id="CognomeReferente" variant="outlined" className="CognomeReferente" sx={{ ...MuiStyles.TextField }} />
                    <TextField label="Numero di contatto Referente" id="NumeroReferente" variant="outlined" className="NumerodicontattoReferente" sx={{ ...MuiStyles.TextField }} />
                </div>
            </div>
            <span className="PanelTitle">Indirizzo di Attestazione Linea</span>
            <div className="RowContainerReseller">
                <TextField label="Via" id="Via" variant="outlined" className="Via" sx={{ ...MuiStyles.TextField }} />
                <CityAutoComplete label="Comune" id="Comune" className="Citta" />
                <TextField label="Civico" id="Civico" variant="outlined" className="Civico" sx={{ ...MuiStyles.TextField }} />
                <TextField label="CAP" id="CAP" variant="outlined" className="CAP" sx={{ ...MuiStyles.TextField }} />
            </div>
        </div>
    )
    const Datirete = (
        <div className="PanelReseller">
            <span className="PanelTitle">Dati di Rete</span>
            <div>
                <div className="PanelReseller" id="Voip">
                    <span className="PanelTitle">Numero Voip</span>
                    <Select id="NumeroVoip" variant="outlined" className="NumeroVoip" sx={{ ...MuiStyles.Select, width: '50%', marginLeft: '2.5%' }} defaultValue={0}
                        onChange={(e) => { setVoip(e.target.value) }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    ...MuiStyles.MenuItem
                                }
                            }
                        }}
                    >
                        <MenuItem value={0}>Attivare Nuovo servizio con Voip</MenuItem>
                        <MenuItem value={1}>Attivare Numero Voip Senza connettività</MenuItem>
                        <MenuItem value={2}>Migrare Voip </MenuItem>
                        <MenuItem value={3}>Non Attivare Voip</MenuItem>
                    </Select>
                    {
                        Voip !== 3 &&
                        <>
                            <div className="RowContainerReseller">
                                <TextField label="Numero Voip" id="NumeroVoip2" variant="outlined" className="NumeroVoip2" sx={{ ...MuiStyles.TextField, width: '90%' }} />
                                <CityAutoComplete label="Comune di Nascita" id="ComuneNascita" className="ComuneNascita" />
                                <TextField label="Prov Nascita" id="ProvinciaNascita" variant="outlined" className="ProvinciaNascita" sx={{ ...MuiStyles.TextField }} />
                            </div>
                            <div className="RowContainerReseller">
                                <TextField label="Tipo Documento" id="TipoDocumento" variant="outlined" sx={{ ...MuiStyles.TextField }} />
                                <TextField label="Ente Emittente" id="EnteEmittente" variant="outlined" sx={{ ...MuiStyles.TextField }} />
                                <TextField label="Numero Documento" id="NumeroDocument" variant="outlined" sx={{ ...MuiStyles.TextField }} />
                            </div>
                            <div className="RowContainerReseller">
                                <TextField label="Data Emissione" id="DataEmissione" variant="outlined" className="DataEmissione" sx={{ ...MuiStyles.TextField }} />
                                <TextField label="Scadenza" id="Scadenza" variant="outlined" className="DataScadenza" sx={{ ...MuiStyles.TextField }} />
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="RowContainerReseller" id="RowConn">
                <div className="PanelReseller" id="Conn">
                    <Select
                        id='Fornitore'
                        fullWidth
                        defaultValue={"Fibercop"}
                        sx={{ ...MuiStyles.Select }}
                        value={Operatore}
                        onChange={(e) => setOperatore(e.target.value)}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    ...MuiStyles.MenuItem
                                }
                            }
                        }}
                    >
                        <MenuItem value="Fibercop">Fibercop</MenuItem>
                        <MenuItem value="OpenFiber">OpenFiber</MenuItem>
                        <MenuItem value="Fastweb">Fastweb</MenuItem>
                        <MenuItem value="Retelit">Retelit</MenuItem>
                        <MenuItem value="Unidata">Unidata</MenuItem>
                        <MenuItem value="FibreConnect">FibreConnect</MenuItem>
                        <MenuItem value="Opnet">Opnet</MenuItem>
                        <MenuItem value="Eolo">Eolo</MenuItem>
                        <MenuItem value="Vodafone">Vodafone</MenuItem>
                        <MenuItem value="Wind">Wind</MenuItem>
                        <MenuItem value="Altro">Altro...</MenuItem>
                    </Select>
                    {Operatore === "Altro" &&
                        <div className="Altro">
                            <TextField id="AltroOperatore" label="AltroOperatore" sx={{ ...MuiStyles.TextField }} />
                        </div>
                    }
                    <Select
                        id='Servizio'
                        fullWidth
                        sx={{ ...MuiStyles.Select }}
                        defaultValue={"ADSL"}
                        value={Servizio}
                        onChange={(e) => setServizio(e.target.value)}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    ...MuiStyles.MenuItem
                                }
                            }
                        }}
                    >
                        <MenuItem value="ADSL">ADSL</MenuItem>
                        <MenuItem value="FTTC">FTTC</MenuItem>
                        <MenuItem value="FTTH">FTTH</MenuItem>
                        <MenuItem value="Fibra Dedicata">Fibra Dedicata</MenuItem>
                        <MenuItem value="Altro">Altro...</MenuItem>
                    </Select>
                    {Servizio === "Altro" &&
                        <div className="Altro">
                            <TextField id="AltroServizio" label="AltroServizio" sx={{ ...MuiStyles.TextField }} />
                        </div>
        }
                    <TextField id="CodMigr" label="Codice Migrazione" sx={{ ...MuiStyles.TextField }} />
                    <TextField id="OperatoreProvenienza" label="Operatore di Provenienza" sx={{ ...MuiStyles.TextField }} />
                </div>
                <div className="PanelReseller" id="Conn2">
                    <FormLabel id="demo-radio-buttons-group-label">Richiesta IP Statici</FormLabel>
                    <RadioGroup defaultValue={"No"} value={Ip} onChange={(e) => setIp(e.target.value)} >
                        <FormControlLabel value="1IP" control={<Radio />} label="1IP" id="1IP"/>
                        <FormControlLabel value="8IP" control={<Radio />} label="8IP" id="8IP"/>
                        <FormControlLabel value="16IP" control={<Radio />} label="16IP" id="16IP"/>
                        <FormControlLabel value="No" control={<Radio />} label="No" id="No"/>
                    </RadioGroup>
                    <div>
                        <FormLabel id="demo-radio-buttons-group-label">Router</FormLabel>
                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" defaultValue={"No"}>
                            <FormControlLabel value="Si" control={<Radio />} label="Si" onChange={() => { setRouter(true) }} />
                            <FormControlLabel value="No" control={<Radio />} label="No" onChange={() => { setRouter(false) }} />
                        </RadioGroup>
                        {Router &&
                            <Select
                                fullWidth
                                id="RouterName"
                                onChange={(e) => { setRouterName(e.target.value) }}
                                defaultValue={"AVM Fritz!Box 7530"}
                                inputProps={{ id: 'Router' }}
                                sx={{ ...MuiStyles.Select }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            ...MuiStyles.MenuItem
                                        }
                                    }
                                }}
                            >
                                <MenuItem value="AVM Fritz!Box 7530">AVM Fritz!Box 7530</MenuItem>
                                <MenuItem value="AVM Fritz!Box 7590">AVM Fritz!Box 7590</MenuItem>
                                <MenuItem value="AVM Fritz!Box 7590 Ax">AVM Fritz!Box 7590 Ax</MenuItem>
                                <MenuItem value="AVM Fritz!Box 6890 LTE">AVM Fritz!Box 6890 LTE</MenuItem>
                                <MenuItem value="AVM Fritz!Box 4040">AVM Fritz!Box 4040</MenuItem>
                                <MenuItem value="AVM Fritz!Box 4060">AVM Fritz!Box 4060</MenuItem>
                                <MenuItem value="AVM Fritz!Box 5530">AVM Fritz!Box 5530</MenuItem>
                                <MenuItem value="AVM Fritz!Box 5530">AVM Fritz!Box 5590</MenuItem>
                                <MenuItem value="Personalizzato">Personalizzato</MenuItem>
                            </Select>
                        }
                        {
                            Router && RouterName === "Personalizzato" &&
                            <div className="Personalized">
                                <TextField id="RouterPers" label="Modello Router" sx={{ ...MuiStyles.TextField }} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
    const FileInput = (
        <div className="PanelReseller">
            <span className="PanelTitle">Documento</span>
            <span className="subtitle">Inserire i documenti in formato Jpeg/png</span>
            <div className="FileBox">
                <div>
                    <span className="FileTitle">Fronte:</span>
                    <FileBoxInput id="FileInput" File={FileName} UpdateFile={(e) => UpdateFile(e, setFile, setFileName)} mimetype="image/png, image/jpeg" />
                </div>
                <div>
                    <span className="FileTitle">Retro:</span>
                    <FileBoxInput id="FileInput2" File={FileName2} UpdateFile={(e) => UpdateFile(e, setFile2, setFileName2)} mimetype="image/png, image/jpeg" />
                </div>
            </div>
        </div>
    )
    const FinalPage = (
        <>
            <div className="PanelReseller">
                <span className="PanelTitle">Contratto Inviato</span>
                <span className="FileTitle">Il contratto è stato inviato con successo</span>
            </div>
            <button className={"btn"} id="ResellerSend" onClick={() => window.location.reload()}  ><span style={{ fontSize: '30px' }} className={"Title"}>Crea Nuovo</span></button>
        </>
    )

    return (
        <div className="Page">
            <span className="Title">Contratto Reseller</span>
            <Warning  title={<span className="titledialog">Attenzione!</span>} id="Basic">
                <div className="desc">
                    <span style={{fontSize: '20px', padding: '10px'}}>{Error}</span>
                </div>
            </Warning>
            {Button}
            <LogOut setStastus={setStastus} />
            {Final ? FinalPage :
                <>
                    {Anag}
                    {FileInput}
                    {Datirete}
                    <button className={"btn"} id="ResellerSend" onClick={SendData} ><span style={{ fontSize: '30px' }} className={"Title"}>Crea</span></button>
                </>
            }

        </div>
    )
}
export default ContrattoRes;