import  "./Css/Bollettino.css"
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css"; // Specify weight and styl
import {TextField} from "@mui/material";
import MuiStyles from "./MuiStyles";
import BackReq from "./BackReq";
import React from "react";
import ByFile from "./ByFile";
const Home = () => {
    return (
        <div className="App">    
            <span className="Title">Bollettino Tlc Online</span>
            <div className="panel">
                <div className="Row">
                    <span className={"SubTitle"}>Dati beneficiario</span>
                    <div className={"InternalPanel"}>
                        <TextField title={"Cc"} label={"Cc"} id={"Cc"} fullWidth inputProps={{maxLength: '12',readOnly: true,style: {color: '#958e8e'}}} defaultValue={"68208719"}  sx={{
                            ...MuiStyles.TextField
                        }} />
                        <TextField title={"Importo in Euro"} label={"Importo in Euro"} id={"ImportoEuro"} inputProps={{maxLength: '8'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }} />
                        <TextField title={"Centesimi"} label={"Centesimi"} id={"Centesimi"} inputProps={{maxLength: '2'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }} />
                        <TextField title={"Importo in Lettere"} label={"Importo in Lettere"} id={"ImportoLettere"} inputProps={{maxLength: '78'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }} />
                        <TextField title={"Intestato a"} label={"Intestato a"} id={"Intestatario"} defaultValue={"TLC Telecomunicazioni srl"} inputProps={{maxLength: '68',readOnly: true,style: {color: '#958e8e'} }}  fullWidth sx={{
                            ...MuiStyles.TextField
                        }} />
                        <TextField title={"Causale"} label={"Causale"} id={"Causale"} inputProps={{maxLength: '150'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }} />
                    </div>
                </div>
                <div className="Row">
                    <span className={"SubTitle"}>Dati ordinante</span>
                    <div className={"InternalPanel"}>
                        <TextField title={"Eseguito da"} label={"Eseguito da"} id={"Eseguito"} inputProps={{maxLength: '92'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }}/>
                        <TextField title={"Via - piazza"} label={"Via - piazza"} id={"Via"} inputProps={{maxLength: '78'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }}/>
                        <TextField title={"CAP"} label={"CAP"} id={"CAP"} inputProps={{maxLength: '5'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }}/>
                        <TextField title={"Località"} label={"Località"} id={"Localita"} inputProps={{maxLength: '78'}} fullWidth sx={{
                            ...MuiStyles.TextField
                        }}/>
                        <button className={"btn"} onClick={BackReq}><span style={{fontSize: '30px'}} className={"Title"}>Crea</span></button>
                    </div>
                </div>
            </div>
            <ByFile/>
        </div>
    )
}
export default Home;
