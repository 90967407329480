import { useNavigate } from "react-router-dom"
import React from "react"
const BackButton = ({id}) => {
    const Navigate = useNavigate()
    return (
        <button id={id} className="btn BackButton" onClick={() => Navigate("/ResellerForm",{replace: true})}>
            <span className="Title" style={{fontSize: '30px'}}>Indietro</span>
        </button>
    )
}
export default BackButton