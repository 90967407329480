
const MuiStyles = {
    TextField: {
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F3440E !important",
            borderRadius: "28px",
            borderWidth: "2px",
            fontSize: 'larger !important'
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: '#F3440E',
            fontSize: 'larger !important',
        },
    },
    Select: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#F3440E', // Border color when focused
        },
    },
    MenuItem: {
        '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: 'rgba(243,68,14,0.2)', // Background color for selected item
        },
        '& .MuiMenuItem-root.Mui-selected:hover': {
            backgroundColor: 'rgba(243,68,14,0.6)', // Background color for selected item on hover
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: 'rgba(243,68,14,0.2)', // Background color for item on hover
        },
    }
}
export default MuiStyles;