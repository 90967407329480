// @ts-nocheck
import React from "react";
import "./Css/Form.css"
import { TextField } from "@mui/material";
import MuiStyles from "./MuiStyles";
import "./Css/Bollettino.css"
import FormRequest from "./FormRequest";
import { useNavigate } from "react-router-dom";
import SmSToken from "./SmSToken";
import ReSend from "./ReSend";
// @ts-ignore
import Contratto from "./docs/Modulo attivazione TLC tutti i servizi con SEPA.pdf"
import { saveAs } from "file-saver";
import arrow from "./Media/arrowIndietro.png";
// @ts-ignore
import FileBoxInput from "./FileBoxInput.tsx";
import DownloadIcon from "./Media/DownloadIcon.png"
import Warning from "./Warning";
const TokenVerify = (nav) =>{
    const navigate = useNavigate()
    const Cell = localStorage.getItem("Cell")
    const [Status, changeStatus] = React.useState({
        Status: false,
        Error: ""
    })
    const [tookData, settookData] = React.useState(false)
    const [File, setFile] = React.useState(['Nessun file selezionato...','Nessun file selezionato...']);
    const [FileData, setFileData] = React.useState([null,null]);
    const [Read, setRead] = React.useState(false)
    /*useEffect(()=>{
        const token = localStorage.getItem("x-access-token")
        if (token === null){
            navigate("/",{replace: true})
        }
    },[])*/
    const update = async() => {
        const error = await FormRequest.FormVerify();
        console.log(error)
        changeStatus({
            Status: true,
            Error: error
        })
        if (error === ""){
            SmSToken.SmsReq(Cell).then((res)=>{
                navigate("/SmsVerify",{replace: true})
            })
        }
    }
    const OnWrong = () => {
        if (Status.Error === "Token non corrispondente")
            changeStatus({
                Status: false,
                Error: ""
            })
        else{
            localStorage.removeItem("x-access-token")
            navigate("/",{replace: true})
        }
    }

    const DataToken = async() =>{
        //document.getElementById("Flag").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        // @ts-ignore
        if (process.env.NODE_ENV === "production"){
            if (!document.getElementById("Flag").checked || document.getElementById("Email").value === "" || document.getElementById("Cell").value === "")
            {
                document.getElementById("Flag").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
                // @ts-ignore
                document.getElementById("Basic").showModal()
                document.getElementById("warnSpan").innerText = "Inserire tutti i dati e flaggare ogni campo"
                return
            }
        }
        const res = await FormRequest.FormRequest(FileData)
        settookData(!res.error)
        if (res.error)
        {            // @ts-ignore
            document.getElementById("Basic").showModal()
            document.getElementById("warnSpan").innerText = res.message
        }
    }

    const SaveContract = () =>{
        setRead(true)
        saveAs(Contratto,"Modulo_attivazione_TLC_2023.pdf")
    }

    const UpdateFile = (e,ind) => {
        const FileArray = [...File]
        FileArray[ind] = e.target.files[0].name
        const FileDataArray = [...FileData]
        FileDataArray[ind] = e.target.files[0]
        setFile(FileArray)
        setFileData(FileDataArray);
    }

    const BackButton = () => {
        return (
            <button className="btn" onClick={()=>navigate(`/Contratto`, { replace: true, state: {Compiled: true } })} style={{width: '45%'}}>
                <div style={{display: 'flex', justifyContent: 'center', columnGap: '0px', alignItems: 'center'}}>
                    <img src={arrow} alt="arrowback" style={{width: "40px", height: '40px'}}/>
                    <span className="Title" style={{fontSize: '30px'}}>Modifica valori</span>
                </div>
            </button>
        )
    }

    const FlagCheck = () =>{
        if (!Read){
            // @ts-ignore
            document.getElementById("Basic").showModal()
            document.getElementById("warnSpan").innerText = "Per contrassegnare questo flag devi prima scaricare e leggere il contratto"
            // @ts-ignore
            document.getElementById("Flag").checked = false
            return
        }
    }

    return (
        <div style={{display:'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>
            <h1>Firma del Contratto</h1>
            {!tookData &&
                <>
                    <Warning title={<span className="titledialog">Attenzione!</span>} id="Basic">
                        <div className="desc">
                            <span id="warnSpan" style={{fontSize: '1.2rem', padding: '10px', lineHeight: '1.3',letterSpacing: '0.04em'}}></span>
                        </div>
                    </Warning>
                    <div style={{rowGap: '10px',width: 'calc(70% * 0.6)', padding: '20px'}} className="Panel">
                        <span  style={{textAlign: 'left',fontSize: '1.1rem', lineHeight: '1.66',letterSpacing: '0.0333em',fontWeight: 600}}>Dati per invio Codice Univoco per firma contratto</span>
                        <div className="rowContainer">
                            <TextField sx={{
                            ...MuiStyles.TextField,
                            width: '70%',
                            }} fullWidth  label="E-Mail" id="Email" variant="outlined" name="Email" />
                            <TextField sx={{
                            ...MuiStyles.TextField,
                            }}  label="Cellulare" id="Cell" variant="outlined" name="Cell" />
                        </div>
                        <div style={{margin: '10px'}}>
                            <span  style={{textAlign: 'left',fontSize: '1.1rem', lineHeight: '1.66',letterSpacing: '0.0333em', paddingTop: '5px', paddingBottom: '5px'}}>Inserire nel campo Foto Fronte/Retro Carta D'identità, i formati accettati sono unicamente <span style={{color: '#F3440E', fontWeight:'600'}}>.jpeg e .png</span></span>
                        </div>
                        <span  style={{textAlign: 'left',fontSize: '1.1rem', lineHeight: '1.66',letterSpacing: '0.0333em', paddingTop: '5px', paddingBottom: '5px', fontWeight: '600'}}>Fronte:</span> 
                        <FileBoxInput UpdateFile={(e)=>UpdateFile(e,0)} File={File[0]} mimetype="image/jpeg, image/png" style={{padding: '0px 20px 0px 20px'}}/>
                        <span  style={{textAlign: 'left',fontSize: '1.1rem', lineHeight: '1.66',letterSpacing: '0.0333em', paddingTop: '5px', paddingBottom: '5px', fontWeight: '600'}}>Retro:</span> 
                        <FileBoxInput UpdateFile={(e)=>UpdateFile(e,1)} File={File[1]} mimetype="image/jpeg, image/png" style={{padding: '0px 20px 20px 20px'}}/>
                        <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '20px'}}>
                            <button className="DownloadButton" onClick={()=>SaveContract()}>
                                <img src={DownloadIcon} alt="DownloadIcon" style={{height: '48px', width: '48px'}}/>
                            </button>
                            <span style={{fontSize: '1.3rem',lineHeight: '1.66', letterSpacing: '0.0333em'}}>Modulo_attivazione_TLC_2023.pdf</span>
                        </div>
                        <div className="rowContainer" style={{margin: '10px',width: '90%',justifyContent:'center'}}>
                            <input type="checkbox" id="Flag" value="Flag" name="Flag" onChange={FlagCheck}/>
                            <label style={{fontSize: '0.95rem',lineHeight: '1.66', letterSpacing: '0.0333em'}}>Dichiaro di aver letto e compreso il contratto che verrà firmato di seguito.</label>
                        </div>
                    </div>
                    <div style={{width: '45%',margin: '20px',columnGap:'30px', display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                        <button style={{margin: 0}} className="btn" onClick={DataToken}><span className="Title" style={{fontSize: '30px'}}>Invia</span></button>
                    </div>
                    <BackButton />
                </>
            }
            {(!Status.Status && tookData && <>
                <div style={{rowGap: '10px',width: 'calc(70% * 0.6)', padding: '20px'}} className="Panel">
                    <span style={{fontSize: '1.1rem',margin: '10px',lineHeight: '1.66',letterSpacing: '0.0333em'}}>
                        Inserire nel campo sottostante il codice univoco ricevuto via <span onClick={SaveContract} style={{ color: '#F3440E', fontWeight: 600, cursor: 'pointer'}}>  Mail</span>.
                    </span>                    
                    <TextField sx={{
                        ...MuiStyles.TextField
                    }}  label="Token" variant="outlined" name="Token" id="MailToken" fullWidth/>
                    
                </div>
                <div style={{width: '45%',height: "",margin: '20px',columnGap:'30px', display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <ReSend recipient={localStorage.getItem("Email")}/>
                    <button className="btn" style={{margin: 0}} onClick={update}><span className="Title" style={{fontSize: '30px'}}>Invia</span></button>
                    
                </div>
                <button className="btn" onClick={()=>navigate(`/Contratto`, { replace: true, state: {Compiled: true } })} style={{width: '45%'}}>
                    <div style={{display: 'flex', justifyContent: 'center', columnGap: '0px', alignItems: 'center'}}>
                        <img src={arrow} alt="arrowback" style={{width: "40px", height: '40px'}}/>
                        <span className="Title" style={{fontSize: '30px'}}>Modifica valori</span>
                    </div>
                </button>
            </>
            )}
            {Status.Status && Status.Error !== "" && 
                <>
                    <h2>Token Errato</h2>
                    <p>{Status.Error}</p>
                    <div style={{width: '50%',margin: '20px', display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                        <button className="btn" onClick={()=>OnWrong()}><span className="Title" style={{fontSize: '30px'}}>Indietro</span></button>
                    </div>
                </>
            }
        </div>
    )
    
}
export default TokenVerify;