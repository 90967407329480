import { Autocomplete,TextField } from "@mui/material"
import React from "react"
import MuiStyles from "./MuiStyles"
const AutocompleteCustom = ({ suggestions, onTextChange, label,id, className }) => {
    return (
        <Autocomplete
            options={suggestions}
            noOptionsText="Nessun risultato"
            id={id}
            className= {className}
            sx={{...MuiStyles.TextField}} 
            renderInput={(params) => (
                <TextField sx={{...MuiStyles.TextField}} {...params} label={label} variant="outlined" onChange={onTextChange} />
            )}
        />
    )
}
export default AutocompleteCustom
