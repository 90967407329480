import AutocompleteCustom from "./AutoCompleteCustom"
import React from "react"
import ApiConnect from "./ApiConnect"
//import MuiStyles from "./MuiStyles"
const CityAutoComplete = ({label,className,id}) => {
    const [suggestion, setSuggestion] = React.useState([])
    const setSuggestionState = async(e) => {
        if (e.target.value.length < 3)
            return
        console.log(await ApiConnect.get("/reseller/FetchComuni",{params: {key: e.target.value}}))
        const resp = await ApiConnect.get("/reseller/FetchComuni",{params: {key: e.target.value}})
        const arr = []
        for (const el of resp.data)
            arr.push(el["Denominazione_in_italiano"])
        arr.sort((a,b) => a.length - b.length)
        setSuggestion(arr)
    }   
    return(
        <>
            <AutocompleteCustom onTextChange={setSuggestionState} suggestions = {suggestion} label={label} id={id} className={className}  />
        </>
    )
}
export default CityAutoComplete