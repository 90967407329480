import React from "react";
import Register from "./Register";
import  "./Css/AdminCp.css";
import RegisterPng from "./Media/Register.png";
import contractPng from "./Media/contract.png";
import WaitingContracts from "./WaitingContracts";
import BackButton from "./BackButton";
const AdminCp = () => {

    const [Component,setComponent] = React.useState("Register") 
    const RenderComponent = (Comp) => {
        switch (Comp) {
            case "Register":
                return <Register />
            case "WaitingContracts":
                return <WaitingContracts />
            default:
                return <Register />
        }
    }
    const NavigationElemnt = (title,img,Comp) => {
        return(
            <div className="NavigationElementAdmin" onClick={() => setComponent(Comp)} >
                {title}
                <img src={img} alt="Register" style={{transform: 'scale(0.67)'}} />
            </div>
        )
    }
    return (
        <div className="FlexRow" style={{justifyContent: 'normal',columnGap: '2%'}}>
            <div className="NavigationBarAdmin">
                {NavigationElemnt("Registra Reseller",RegisterPng, "Register")}
                {NavigationElemnt("Contratti Reseller",contractPng, "WaitingContracts")}
            </div>
            <BackButton id={"AdminBackButton"} />
            <div id="ContainerComponent" style={{width: '80%'}}>
                {RenderComponent(Component)}
            </div>
        </div>
    );
}   
export default AdminCp;