import React from "react";
import { TextField } from "@mui/material";
import MuiStyles from "./MuiStyles";
const Pagamento = ({funcs,states}) => {
    const Text_Content = states.Text_Content
    const setValue = funcs.setValue
    const Shrink = funcs.Shrink
    return (
        <form style={{display: 'flex',flexDirection: 'column',rowGap: '10px'}} className="Form">
                <h3>Modalità di Pagamento</h3>
                <div style={{rowGap: '0px',width: '100%'}} className="Panel">
                        <div style={{padding: '20px'}}>
                            <div style={{width: '100%'}}>
                                <span style={{fontWeight: 'bold',textAlign: 'left',marginLeft: '5px'}}>Addebito automatico su Conto Corrente</span>
                            </div>
                            <div className="basicFlexColumn" style={{margin: '10px',width: '95%'}}>
                                <div className="rowContainer">
                                    <TextField sx={{
                            ...MuiStyles.TextField,
                            width: '70%'
                            }}  label="Codice Iban" onChange={setValue} value={Text_Content["Iban"]} name="Iban" InputLabelProps={{ shrink:  Shrink("Iban")}} />
                                    <TextField sx={{
                            ...MuiStyles.TextField
                            }}  label="Intestatario Conto*" onChange={setValue} value={Text_Content["Intestatario"]} name="Intestatario" InputLabelProps={{ shrink:  Shrink("Intestatario")}}/>
                                </div>
                                <div className="rowContainer">
                                    <TextField sx={{
                            ...MuiStyles.TextField
                            }}  label="Legale Rappresentante" fullWidth onChange={setValue} value={Text_Content["Legale"]} name="Legale" InputLabelProps={{ shrink:  Shrink("Legale")}}/>
                                    <TextField sx={{
                            ...MuiStyles.TextField
                            }}  label="Codice Fiscale*" fullWidth onChange={setValue} name="Codice_Fiscale_OHVO" value={Text_Content["Codice_Fiscale_OHVO"]} InputLabelProps={{ shrink:  Shrink("Codice_Fiscale_OHVO")}}/>
                                </div>
                                <div style={{width: '100%'}}>
                                    <span style={{fontSize: '0.85rem',margin: '0px',lineHeight: '1.66',letterSpacing: '0.0333em'}}>
                                        *per le società indicare nome cognome e codice fiscale di chi ha la firma in banca
                                    </span>
                                    <br />
                                    <span style={{fontSize: '0.85rem',margin: '0px',lineHeight: '1.66',letterSpacing: '0.0333em'}}>
                                        Attenzione! Qualora l’intestatario del conto fosse diverso dall’intestatario del contratto con TLC (debitore) è necessario il documento d’identità, 
                                        la firma ed il consenso di entrambi i soggetti.
                                    </span>
                                </div>
                                <span style={{fontSize: '0.65rem',margin: '0px',lineHeight: '1.66',letterSpacing: '0.0333em',fontWeight: '400'}}>
                                    La sottoscrizione del mandato comporta l’autorizzazione a TLC Telecomunicazioni srl a richiedere alla banca del debitore l’addebito sul suo conto e l’autorizzazione alla banca del debitore di procedere a tale addebito conformemente alle disposizioni
                                    impartite dal creditore. Il debitore ha diritto di ottenere il rimborso dalla propria banca secondo gli accordi ed alle condizioni che regolano il rapporto con quest’ultima. Se del caso, il rimborso deve essere richiesto nel termine di 8 settimane a decorrere
                                    dalla data di addebito in conto. Autorizzo TLC Telecomunicazioni s.r.l. ad addebitarmi tutti gli oneri convenuti, relativi al presente contratto. Non mi è richiesto un consumo minimo mensile per utenza. Sono consapevole di avere 30 giorni di tempo dalla
                                    data di emissione delle singole bollette per effettuare eventuali contestazioni, da inoltrarsi a TLC Telecomunicazioni s.r.l. per iscritto via posta presso la sede legale. Sono consapevole di potere in ogni momento recedere dal presente contratto. Con la
                                    presente inoltre mi assumo la completa responsabilità finanziaria per ogni telefonata effettuata attraverso le mie linee telefoniche per le quali ho richiesto l'attivazione a TLC Telecomunicazioni s.r.l.
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
    );
}
export default Pagamento;