// @ts-nocheck
import React, { useEffect } from "react"
import "./Css/Bollettino.css"
import "./Css/ResellerCp.css"
import ApiConnect from "./ApiConnect"
import expandpng from "./Media/expand.png"
import { useState } from "react"
import DownloadIcon from "./Media/DownloadIcon.png"
import BackAdminCp from "./BackAdminCp"
import { Select, TextField } from "@mui/material"
import Alert from "./Alert"
import MuiStyles from "./MuiStyles"
import {MenuItem} from "@mui/material"
const WaitingContracts = () => {
    const [Requests, setRequests] = React.useState([])
    const [RequestsEl, setRequestsEl] = React.useState([])
    const [AlertMessage, setAlertMessage] = useState("")
    useEffect(() => {
        const fetch = async() => {
            return ApiConnect.get("/reseller/GetResellerWaitContracts", {
                headers: {
                    "logintoken": localStorage.getItem("logintoken")
                }
            }).then(res => {
                setRequests(res.data)

            })
        }
    fetch()
    /*setRequests([
        {
            Date: "2024-07-30",
            Nome: "Mario",
            Cognome: "Rossi",
            Status: true
        },
        {
            Date: "2024-07-28",
            Nome: "mario",
            Cognome: "Bianchi",
            Status: false
        },
        {
            Date: "2024-07-25",
            Nome: "Giulia",
            Cognome: "Verdi",
            Status: true
        },
        {
            Date: "2024-07-22",
            Nome: "Elena",
            Cognome: "Neri",
            Status: false
        }
    ])*/
    }, [])
    const [expandedItems, setExpandedItems] = useState("");
    useEffect(() => {
        const fetch = async() => {
            return ApiConnect.get("/reseller/GetResellerWaitContracts", {
                headers: {
                    "logintoken": localStorage.getItem("logintoken")
                }
            }).then(res => {
                setRequests(res.data)

            })
        }
        const expand = (key) => {
            const el = document.getElementById("id" + key)
            
            if (el.style.height === "28vh"){
                el.style.transition = "all 0.5s"
                el.style.height = "6.5vh"
                el.style.minHeight = "6.5vh"
                setExpandedItems("")
                return            
            }
            el.style.transition = "all 0.5s"
            el.style.minHeight = "28vh"
            el.style.height = "28vh"
            el.style.alignItems = "normal"
            setExpandedItems(key)
            
            if (expandedItems !== ""){
                document.getElementById("id" + expandedItems).style.height = "6.5vh"
                document.getElementById("id" + expandedItems).style.minHeight = "6.5vh"
                document.getElementById("id" + expandedItems).style.transition = "min-height 0.5s"
            }   
            
            
        }
        const Update = (Id,key) => {
            //inserire error handling
            const Status = document.getElementById("sel"+ key).innerText
            const Note = document.getElementById("Note"+ key).value
            console.log(Status,Note)
            BackAdminCp.Update(Id,Status,Note); 
            setAlertMessage("Richiesta Approvata Correttamente")
            document.getElementById("Basic").showModal(); 
            fetch()
            expand(key)
        }
        const StatusTable = {
            "Espletato" : "rgb(158, 195, 133)",
            "In lavorazione": "rgb(209, 176, 13)",
            "Incompleto" : "rgb(209, 176, 13)",
            "Sospeso" : "rgb(209, 176, 13)",
            "Ko con accodamento" : "rgb(242, 148, 24)",
            "Ko" : "rgb(210, 4, 45)",
        }

        const SetColor = (key,value) => {
            document.getElementById("sel"+ key).style.color = StatusTable[value]
        }

        const SelectStatus = (key) => {
            return(
                
                <Select defaultValue={"In lavorazione"} style={{ color: "rgb(209, 176, 13)", fontWeight: '600',padding: '0.5%', marginLeft: '2%'}} id={"sel"+ key} onChange={(e)=>SetColor(key,e.target.value)}>
                    {Object.keys(StatusTable).map((element,index) => {
                        return <MenuItem style={{color: StatusTable[element], fontWeight: '600'}} key={"Status" + index} value={element}>{element}</MenuItem>

                    })}
                    
                </Select>
            )
            
        }

        const DownloadButton = (key,title,func) => {
            const funchere = () => {
                if (func === 0)
                    BackAdminCp.DonwloadLogs(Requests[key].Id)
                else
                    BackAdminCp.DownloadDocuments(Requests[key].Id)
            }
            return (
                <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '10px'}}>
                    <span style={{fontSize: '1rem',lineHeight: '1.66', letterSpacing: '0.0333em', fontWeight: '600', color: 'var(--orange',maxWidth: '100%'}}>{title}</span>
                    <button className="DownloadButton" style={{ transform: 'scale(0.7)'}} onClick={funchere}>
                        <img src={DownloadIcon} alt="DownloadIcon" style={{height: '48px', width: '48px'}}/>
                    </button>
                </div> 
            )
        }
            
        
        const Request =(key,Date,Name,Surname,Status) => {
            return (
                <div className="Request" key={key} id={"id" + key}>
                    <div className="InternalRequest">
                        <div className="ReqElemnt">
                            <span className="RequestDate">{Date}</span>
                        </div>
                        <div className="ReqElemnt">
                            <span className="RequestName">{Name}</span>
                        </div>
                        <div className="ReqElemnt">
                            <span className="RequestSurname">{Surname}</span>
                        </div>
                        <div className="ReqElemnt" style={{paddingRight: '0'}}>
                            <span className="RequestStatus" style={{color: StatusTable[Status]}}>{Status}</span>
                        </div>
                        <div className="ReqElemnt" id="expand">
                            <img onClick={() => expand(key)} src={expandpng} alt="Expand" style={{transform: 'scale(1)' }} />
                        </div>
                    </div>
                        <div className={expandedItems === key ? "InternalExpand" : "NotDisplay"}>
                            <div className="rowContainer">
                                
                                <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '20px'}}>
                                    {DownloadButton(key,"Scarica i Log",0)}
                                    {DownloadButton(key,"Scarica i Documenti",1)}
                                </div>   
                            </div>
                            <div className="rowContainer" style={{columnGap: '5%', alignItems: 'center'}}>
                                {SelectStatus(key)}
                                <TextField  sx={{
                                    ...MuiStyles.TextField,
                                    marginRight: '2%',
                                }}  label="Note"  variant="outlined" id={"Note"+key} fullWidth/>                            
                            </div>
                            <button style={{width: '40%', height: '35%', marginTop: '0.5%'}} onClick={()=>{Update(Requests[key].Id,key)}} id="Btn" className={"btn"}><span style={{fontSize: '25px'}} className={"Title"}>Aggiorna</span></button>
                        </div>
                </div>
        )}
    
        const RequestHeader = (key,Date,Name,Surname,Status) => {
            return (<div className="Request" id={"id" + key}>
                <div className="InternalRequest">
                    <div className="ReqElemnt">
                        <label className="LabelDate">Data:</label>
                        <span className="RequestDate">{Date}</span>
                    </div>
                    <div className="ReqElemnt">
                        <label className="LabelDate">Nome:</label>
                        <span className="RequestName">{Name}</span>
                    </div>
                    <div className="ReqElemnt">
                        <label className="LabelDate">Cognome:</label>
                        <span className="RequestSurname">{Surname}</span>
                    </div>
                    <div className="ReqElemnt" style={{paddingRight: '0'}}>
                        <label className="LabelDate" style={{paddingBottom: '3px'}}>Stato:</label>
                        <span className="RequestStatus" style={{color: StatusTable[Status]}}>{Status}</span>
                    </div>
                    <div className="ReqElemnt" id="expand">
                        <img onClick={() => expand(key)} src={expandpng} alt="Expand" style={{transform: 'scale(1)' }} />
                    </div>
                </div>
                <div className={expandedItems === key ? "InternalExpand" : "NotDisplay"}>
                            <div className="rowContainer">
                                
                                <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '20px'}}>
                                    {DownloadButton(key,"Scarica i Log")}
                                    {DownloadButton(key,"Scarica i Documenti")}
                                </div>   
                            </div>
                            <div className="rowContainer" style={{columnGap: '5%', alignItems: 'center'}}>
                                {SelectStatus(key)}
                                <TextField  sx={{
                                    ...MuiStyles.TextField,
                                    marginRight: '2%',
                                }}  label="Note"  variant="outlined" id={"Note"+key} fullWidth/>                         
                            </div>
                            <button style={{width: '40%', height: '35%', marginTop: '0.5%'}} onClick={()=>{Update(Requests[key].Id,key)}} id="Btn" className={"btn"}><span style={{fontSize: '25px'}} className={"Title"}>Aggiorna</span></button>
                        </div>
                </div>
            )
        }
        const arr = []
        Requests.forEach((element, index) => {
            if (index === 0)
                arr.push(
                    RequestHeader(index, element.Date, element.Nome, element.Cognome, element.Status)
                )
            else 
                arr.push(
                    Request(index, element.Date, element.Nome, element.Cognome, element.Status)
                )
        })
        setRequestsEl(arr)
    }, [Requests,expandedItems])


    return (
        <div className="ColumnFlex" style={{justifyContent: 'center', flexDirection: 'row',width: '100%'}}>

            <div className="panel" id="WaitingContracts" style={{width: "60%", height: '90%'}} >
                <Alert id="Basic" title={<span className="titledialog">Approvazione</span>}>
                    <p style={{fontSize: '20px', padding: '0px', width: '100%', textAlign: 'center'}}>{AlertMessage}</p>
                    <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',width: '100%'}}>
                        <button className="btn" style={{width: '30%', margin: 0}} onClick={()=>{document.getElementById("Basic").close();}}><span className="Title" style={{fontSize: '30px'}}>Ok</span></button>
                    </div>
                </Alert>
                <div style={{ width: '100%', height: '100%' }}>
                    <span className="SubTitle">Richieste:</span>
                    <div className="InternalPanel" id="PannelloRichieste" style={{ rowGap: '5px',maxHeight: '55vh', overflowY: 'scroll'  }}>
                        {RequestsEl}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WaitingContracts