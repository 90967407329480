// @ts-nocheck
import React from 'react';
import "./Css/PopUp.css"
import close from './Media/close.png';
import PropTypes from 'prop-types';
const Alert = ({children,style,className,id,title}) =>{
    return (
        <dialog className={className + " popup"} id={id} style={style}>
            <div className='popuptitlebox'>
                <div style={{textAlign: 'center', flex: 1,paddingLeft: '50px'}}>
                    {title}
                </div>
                <img src={close} alt='close.png' style={{ height:'75%',padding: '10px',cursor: 'pointer',}} onClick={() => document.getElementById(id).close()} />
            </div>
            <div className='ColumnFlexContainerPopup'>
                {children}
            </div>
            
        </dialog>
    )
    
}
Alert.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.node.isRequired
  };
export default Alert;