import ApiConnect from "./ApiConnect";
import FileSaver from "file-saver";
const DonwloadLogs = (Id) => {
    ApiConnect.get("/reseller/DataContract", {
        headers: {
            "logintoken": localStorage.getItem("logintoken")
        },
        params: {
            Id: Id
        }
    }).then(res => {
        const element = document.createElement("a");
      const file = new Blob([JSON.stringify(res.data[0].Data)], { type: "application/json" });
      element.href = URL.createObjectURL(file);
      element.download = "Log.json";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      document.body.removeChild(element);
    })
}
const DownloadDocuments = (Id) => {
    ApiConnect.get("/reseller/IdCard", {
        headers: {
            "logintoken": localStorage.getItem("logintoken"),
            "Content-Type": "application/json",
          // If expecting binary data:
          "Accept": "application/octet-stream"
        },
        params: {
            Id: Id
        },
        responseType: "arraybuffer"
    }).then(async(res) => {
        FileSaver.saveAs(new Blob([res.data], { type: "zip" }), "Documenti.zip");
    })
}

const Update = (Id,Status,Note) => {
    ApiConnect.post("/reseller/UpdateContract", {
        Id: Id,
        Status,
        Note
    }, {
        headers: {
            "logintoken": localStorage.getItem("logintoken")
        }
    }).then(res => {
        return res.data.status
    })
}
const BackAdminCp = { DonwloadLogs, DownloadDocuments, Update }
export default BackAdminCp