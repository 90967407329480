import React from "react"
import TextField from '@mui/material/TextField';
import MuiStyles from "./MuiStyles";
const Anag = ({funcs}) =>{
    const setValue = funcs.SetValue
    const Shrink = funcs.Shrink
    return( 
        <form style={{display: 'flex',flexDirection: 'column',rowGap: '10px'}} className="Form">
            <h3>Dati Anagrafici</h3>
            <div style={{rowGap: '0px'}} className="Panel">
                <div className="basicFlexColumn" style={{margin: '10px',width: '95%'}}>
                    <div className="rowContainer">
                        <TextField  sx={{
                        ...MuiStyles.TextField
                        }}  label="Nome" InputLabelProps={{ shrink: Shrink("Nome")}}  variant="outlined" name="Nome" helperText="*In caso di società indicare nome e cognome del legale rappresentante" fullWidth onChange={setValue}/>
                        <TextField sx={{
                        ...MuiStyles.TextField
                        }}  label="Cognome" variant="outlined" name="Cognome" fullWidth onChange={setValue} InputLabelProps={{ shrink: Shrink("Cognome")}}/>
                    </div>
                    
                    <TextField sx={{
                        ...MuiStyles.TextField
                        }}  label="Ragione Sociale" variant="outlined" name="Ragione_Sociale" helperText="*Solo aziende" InputLabelProps={{ shrink: Shrink("Ragione_Sociale")}} fullWidth onChange={setValue}/>
                    <div className="rowContainer" style={{marginTop: '-5px'}}>
                        <TextField sx={{
                        ...MuiStyles.TextField,
                        width: '80%'
                        }}  label="Via" variant="outlined" name="Via" InputLabelProps={{ shrink: Shrink("Via")}} onChange={setValue}/>
                        <TextField sx={{
                        ...MuiStyles.TextField,
                        width: '20%'
                        }}  label="Civico" variant="outlined" name="Civ" InputLabelProps={{ shrink: Shrink("Civ")}} onChange={setValue}/>
                    </div>
                    <div className="rowContainer" id="IndirizzoForm">
                        <TextField sx={{
                        ...MuiStyles.TextField,
                        width: '70%'
                        }}  label="Città" variant="outlined" name="Citta" InputLabelProps={{ shrink: Shrink("Citta")}} onChange={setValue}/>
                        <div id="Cap" >
                            <TextField sx={{
                            ...MuiStyles.TextField,
                            
                            }} fullWidth label="CAP" variant="outlined" name="Cap" InputLabelProps={{ shrink: Shrink("Cap")}} onChange={setValue}/>
                        </div>
                        <div id="Provincia"> 
                        <TextField sx={{
                        ...MuiStyles.TextField,
                    
                        }} fullWidth label="Prov." variant="outlined" name="Provincia" InputLabelProps={{ shrink: Shrink("Provincia")}} onChange={setValue}/>
                        </div>
                    </div>
                    <div className="rowContainer">
                        <TextField sx={{
                        ...MuiStyles.TextField
                        }}  label="Telefono" variant="outlined"  name="Tel" InputLabelProps={{ shrink: Shrink("Tel")}} onChange={setValue}/>
                    </div>
                    <div className="rowContainer">
                        <TextField sx={{
                        ...MuiStyles.TextField
                        }}  label="Codice Fiscale" variant="outlined" name="Codice_Fiscale" InputLabelProps={{ shrink: Shrink("Codice_Fiscale")}} fullWidth onChange={setValue}/>
                        <TextField sx={{
                        ...MuiStyles.TextField
                        }}  label="Partita Iva" variant="outlined" name="Partita_Iva" InputLabelProps={{ shrink: Shrink("Partita_Iva")}}  fullWidth onChange={setValue}/>
                    </div>
                    <TextField sx={{
                        ...MuiStyles.TextField
                        }}  label="Contatto" variant="outlined" name="Contatto" fullWidth onChange={setValue} InputLabelProps={{ shrink: Shrink("Contatto")}} helperText="*Persona da contatttare per comunicazioni inerenti il contratto"/>
                    <TextField sx={{
                        ...MuiStyles.TextField,
                        marginTop: '-5px'
                    }}  variant="outlined" InputLabelProps={{ shrink:  Shrink("Codice_Uni")}} name="Codice_Uni" label="Codice Univoco e/o indirizzo Pec per fatturazione elettronica"fullWidth onChange={setValue} helperText="*L’indicazione del codice univoco e/o delle PEC sono obbligatori per i clienti possessori di P.IVA. I clienti privati non hanno codice univoco mentre possono indicare una PEC su base volontaria. Qualora il
                        codice univoco non venisse indicato verrà utilizzato il codice convenzionale “0000000” e la fattura verrà recapitata nel proprio cassetto fiscale sul portale dell’Agenzia delle Entrate."/>
                    <TextField sx={{
                        ...MuiStyles.TextField
                    }}  label="E-Mail per Fatturazione di Cortesia" variant="outlined" name="Email_Fatt_Elett" InputLabelProps={{ shrink: Shrink("Email_Fatt_Elett")}}  helperText="*qualora il privato non indichi nulla verrà inviata la fattura cartacea, qualora l’azienda ed i possessori di P.IVA non indichino nulla verrà inviata la sola fattura elettronica" fullWidth onChange={setValue} />
                </div>
            </div>
        </form>
    )
}
export default Anag