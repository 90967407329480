import ApiConnect from './ApiConnect';
// @ts-ignore
import FileSaver from 'file-saver';
// @ts-ignore
const XLSX = require('xlsx')

const ParseFile = async(inpt)=>{
    const filereader = new FileReader();
    if (inpt === null) {
        return {
            status: false,
            message: "Nessun file selezionato"
        }
    }
    if (inpt.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return {
            status: false,
            message: "Il file deve essere in formato .xlsx"
        }
    }
    filereader.readAsArrayBuffer(inpt)
    const workbook = await new Promise((resolve)=>{filereader.onload = ()=>{ 
        resolve(XLSX.read(filereader.result, {type: 'array'}));
        
    }})
    const sheetName = workbook.SheetNames[0]
    const sheet = workbook.Sheets[sheetName]
    let obj = {}
    obj = XLSX.utils.sheet_to_json(sheet)
    console.log(obj)
    return ApiConnect.post("/ByFile",obj,{
        responseType: 'arraybuffer',
    }).then((res)=>{
        FileSaver.saveAs(new Blob([res.data]),"Bollettino.pdf")
        return {
            status: true,
            message: ""
        }
    })
}
export default ParseFile