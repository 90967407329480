import ApiConnect from "./ApiConnect";
import promise from "promise";
import FileSaver from "file-saver";
const SmsVerify = async(Token,sessionID,check3) =>{
    return await new promise((resolve,reject) =>{
        sessionID = localStorage.getItem("x-access-token")
        ApiConnect.post("/form/verifySMS",{
            Token,
            Email: localStorage.getItem("Email"),
            Trattamento: check3,
        },{
            headers: {
                withCredentials: true,
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json',
                "x-access-token": localStorage.getItem("x-access-token"),
                responseType: 'arraybuffer'
            }
        }).then((res)=>{
            const statuscode = {
                "00" : "Token scaduto", 
                "01" : "Troppi tentativi",
                "02" : "Token non corrispondente",
                "03": "Sessione non trovata o Scaduta",
            }
            if (res.data.Status){
                ApiConnect.post("/form/GetSignedContract",{},{
                    responseType: 'arraybuffer',
                    headers: {
                        withCredentials: true,
                        'Access-Control-Allow-Origin': '*', 
                        'Content-Type': 'application/json',
                        "x-access-token": localStorage.getItem("x-access-token"),
                        
                    }
                })
                .then((res)=>{
                    FileSaver.saveAs(new Blob([res.data]),"Contratto.pdf")
                })
                resolve("")

            }
            else
                resolve(statuscode[res.data.ErrorCode])
        })
    })
}
const SmsReq = async(Number) =>{
    return await new promise((resolve,reject) =>{
        const sessionID = localStorage.getItem("x-access-token")
        ApiConnect.post("/form/CreateSMS",{
            "Cell" : Number,
            sessionID
        }).then((res)=>{
            localStorage.setItem("MailSession",localStorage.getItem("x-access-token"))
            localStorage.setItem("x-access-token",res.data["x-access-token"])
            resolve(res.data)
        })
    })
}
const exports = {SmsVerify,SmsReq}
export default exports;