import FileSaver from "file-saver";
import ApiConnect from "./ApiConnect";
const BackReq = () =>{
    const Ids = [
        "ImportoEuro",
        "Centesimi",
        "ImportoLettere",
        "Causale",
        "Eseguito",
        "Via",
        "CAP",
        "Localita"
    ]
    const Req = {};
    Ids.forEach((value) => Req[value] = document.getElementById(value).value)
    ApiConnect.post("/create",Req,{ responseType: 'arraybuffer' })
        .then((res)=>{
            FileSaver.saveAs(new Blob([res.data], {type: "application/pdf;"}), "Bollettino.pdf");
    })
}
export default BackReq;