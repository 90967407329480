import React, { ChangeEvent } from "react"
const FileBoxInput:React.FC<FileBoxInputInterface> = ({UpdateFile, File, style, mimetype, LabelId, TextId}) => {
    return (
        <div className='FlexRow' style={style}>
            <label className="fileInput" id={LabelId} style={{ width: '30%' }}>
                <input type="file" onChange={UpdateFile} accept={mimetype}/>
                Scegli il file
            </label>
            <span style={{ marginLeft: '15px' }} id={TextId} className='SubTitle'>{File}</span>
        </div>
    )
}

interface FileBoxInputInterface {
    style ?: React.CSSProperties,
    File : string,
    UpdateFile : (e: ChangeEvent) => void
    mimetype ?: string
    LabelId ?: string
    TextId ?: string
}

export default FileBoxInput
